
$(function() {

  /*
 * Check if the browser supports `position: sticky` natively
 */

  const isWindowDefined = typeof window !== 'undefined';

  // This file is meaningless if not in a browser
  if (!isWindowDefined || !window.getComputedStyle) {
    return false;
  }

  const prefix = [
    '',
    '-o-',
    '-webkit-',
    '-moz-',
    '-ms-',
  ];
  const test = document.head.style;
  for (let i = 0; i < prefix.length; i++) {
    test.position = `${prefix[i]}sticky`;
  }
  let stickyProp = test.position ? test.position : 'fixed';

  if (stickyProp === 'fixed') {
    return false;
  }

  // HEADER / NAV BAR

  $('#uark-mobile-menu-button').on('click', function() {
   // window.scrollTo(0, 0);
   // $('#uark-mobile-menu').css('top', 0);


  });

});
