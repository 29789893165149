/*

*** File paths are relative to THIS FILE ***

Imports beginning with ./ are local files
All other imports are node_modules
*/

/*
   Static assets, listed so webpack knows to include them in the bundle.
   If an asset is referenced from JS or CSS, it will be inlined if the size is
   less than the limit in webpack.config.js
*/
import '../sass/uark-base.scss'; // CSS is bundled separately, but must be referenced here
// import faSetup from './_shared/fontawesome-setup';
// import './_shared/atozscript';
// import 'moment';
import 'jquery';
import $ from 'jquery';
import 'bootstrap';
import './_shared/oucampus-2.js';
// import './_shared/nav-setup.js';
import './_shared/header.js';
// import autoAnchor from './_shared/autoAnchor.js';
import './_shared/bootstrap-setup.js';

window.UARK = {
  analytics: { initialized: false },
  info     : 'Global namespace object for UARK-specific initialization and libraries.',
  util     : { findNearestHeading: require('./util/findNearestHeading.js') },
  settings : Object.assign({ autoAnchor: true }, window.settings || {}),
};

/*
MAIN INIT
*/

// faSetup();
// navSetup();
// autoAnchor();

(() => {
  // lazy load analytics code to take advantage of code splitting
  import('./_shared/analytics.js').then((analytics) => {
    window.trackingIds = window.trackingIds || [];
    window.UARK.analytics = analytics.init(window.trackingIds);
    window.addEventListener('error', window.__e = function f(e) {
      f.q = f.q || [];
      f.q.push(e);
    });
    window.trackingIds = window.trackingIds || [];
    window.trackingIds.push('UA-18311705-26');
  });

  // Initiate all other lazy code paths here...
  if ($('pre code, .force-select').length) {
    import('./_shared/clipboard-setup.js'); // side effects, no vars
    import('./_shared/code-highlighting.js');
  }
  if ($('[data-toggle="hideseek"]').length) {
    // This is so small, it's not neccessary to lazy load, but it's a good example.
    import('./_shared/hideseek-local.js').then((hideseek) =>
      hideseek.uarkHideseek());
  }
  // if ($('.fad').length) {
  //   import('../../node_modules/@fortawesome/fontawesome-pro/scss/duotone.scss');
  // }
  // if ($('.fa').length) {
  //   import(
  //     '../../node_modules/@fortawesome/fontawesome-pro/scss/v4-shims.scss'
  //   );
  // }
})(window);
