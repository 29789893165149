
export default function findNearestHeading($el) {
  const selector = 'h1,h2,h3,h4,h5,legend';
  const $sibling = $el.prev(selector);
  if ($sibling.length) {
    return $sibling.last().text();
  }
  const $commonAncestor = $el.prevAll().find(selector);
  if ($commonAncestor.length) {
    return $commonAncestor
      .first()
      .text();
  }
  const $parent = $el.parent();
  if ($parent.is(selector)) {
    return $parent.text();
  }
  return findNearestHeading($parent);
}
