import $ from 'jquery';

$(function() {
  $('[data-toggle="offcanvas"]')
    .on('click', function() {
      $('.offcanvas-collapse').toggleClass('open');
      $(document.body).toggleClass('no-scroll');
    });

  $('.popover-dismiss').popover({trigger: 'focus'});

  $('.dropdown-toggle,[data-toggle="dropdown"]').dropdown();
  $('[data-toggle="tooltip"]').tooltip();

  $('[data-bs-toggle="collapse"]').collapse();

});
