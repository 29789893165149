import $ from 'jquery';
import 'bootstrap';

$(function() {

  addImgFluidClass();

  //directedit();

});//end ready


function addImgFluidClass() {
  //  iterate through any image found within main-container div
  $('#main-container img').each(function() {
    $('img:not(.img-fluid, .no-img-responsive, .no-img-fluid, .media)').addClass('img-fluid');
  });
}


// eslint-disable-next-line no-unused-vars
function accordionTabOpenScroll() {
  let windowWidth = $(window).width();
  let widthCheck = 308;
  // let windowHeight = $(window).height();
  let setOffset = 1000;

  //$(document).on('click', 'a[href*=#]:not([href=#])', function(e)
  $(document).on('click', 'a[href*=#]:not([href=#]):not([href^=#filter=])', function(e) {
    e.preventDefault();
    e.stopPropagation();

    let str = $(this).attr('href');
    let res = str.replace('#', '');

    // Handle any anchors that exist in tabs
    // Firstly, open the tab

    let found_id = $('#main-container').find('a[name=' + res + ']')
      .closest('div')
      .attr('id');

    $('#myTab a[href="#' + found_id + '"]').tab('show');

    // Close any open accordions if they exist
    $('.panel-collapse.in').addClass('collapse')
      .removeClass('in');

    // Now open the accordion if it exists
    $('#main-container').find('a[name=' + res + ']')
      .parents(':not(.panel-collapse.collapse.in)')
      .collapse('show');

    // Scroll to the anchor
   // $('html, body').animate({ scrollTop: $('[name="' + res + '"]').offset().top }, setOffset);
    return false;
  });


  // if an anchor section is specified in the URL and is not equal to ''
  if (location.hash !== '') {

    console.log("test on location.hash");

    let str = location.hash;
    let res = str.replace('#', '');

    // Now open the accordion if it exists
    $('#main-container').find('a[name=' + res + ']')
      .parents(':not(.panel-collapse.collapse.in)')
      .collapse('show');

    if (str.length) {

      if (windowWidth >= widthCheck) {
        console.log("widthCheck 1: " + widthCheck);
        $('html,body').animate({ scrollTop: $('[name="' + res + '"]').offset().top}, setOffset);
      } else if (windowWidth < widthCheck) {

        //console.log('no resize - width is less than 308');
        // eslint-disable-next-line no-magic-numbers
        //$('html,body').animate({scrollTop: $('[name="' + res + '"]').offset().top - 18}, setOffset);
        console.log("widthCheck 2: " + widthCheck);
      }
      return false;
    }


    $(document).on('click', 'a[href*=#]:not([href=#])', function() {
      $('html, body').animate({ scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top}, setOffset);
      return false;
    });


  } else {
console.log("bottom logic test");
    $(document).on('click', 'a[href*=#]:not([href=#])', function() {

      $('html, body').animate({scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top}, setOffset);
      return false;
    });
  }

}

